<template>
  <div style="color: #003e5d">
    <div class="row mt-4">
      <div class="col-12">
        <strong>
          <label class="typo__label">
            Selecione a(s) Unidade(s) para Compartilhar
          </label>
        </strong>
        <Multiselect
          v-model="setoresCompartilhamento"
          :close-on-select="false"
          :multiple="true"
          :options="setoresSemCompartilhamento"
          :searchable="true"
          :show-labels="false"
          :taggable="true"
          label="nome"
          placeholder="Unidades"
          track-by="nome"
          @tag="addTag"
        ></Multiselect>

        <label class="mt-3"><b>Mensagem de Compartilhamento</b></label>
        <b-form-textarea
          v-model="compartilhamento.mensagem"
          class="form-control"
          cols="30"
          required
          rows="10"
        ></b-form-textarea>
      </div>
    </div>
    <b-row class="mt-3">
      <div class="col-12">
        <div
          v-if="processo.compartilhamento.length === 1"
          class="d-flex justify-content-between flex-wrap"
        >
          <b-form-checkbox
            id="checkbox-1"
            v-model="manter_processo_unidade"
            v-b-tooltip.hover
            disabled
            name="checkbox-1"
            title="NECESSÁRIO TER 1 OU MAIS UNIDADES COMPARTILHADAS"
          >
            <b>Manter Processo Visível Nesta Unidade</b>
          </b-form-checkbox>
          <b-button
            size="sm"
            variant="primary"
            @click="compartilharProcesso()"
          >
            Compartilhar
          </b-button>
        </div>

        <div v-else class="d-flex justify-content-between flex-wrap">
          <b-form-checkbox
            id="checkbox-1"
            v-model="manter_processo_unidade"
            name="checkbox-1"
            @change="removerCompartilhamentoProcessoDaUnidade"
          >
            <b>Manter Processo Visível Nesta Unidade</b>
          </b-form-checkbox>

          <b-button
            size="sm"
            variant="primary"
            @click="compartilharProcesso()"
            :disabled="signatarios.length > 0"
          >
          Compartilhar
          </b-button>
        </div>
      </div>
    </b-row>
    <br />
    <div class="row text-center justify-content-center">
      <div class="col-12 ml-3 mt-1 table-responsive">
        <table class="table table-striped table-hover">
          <tr style="color: #003e5d">
            <th>Data</th>
            <th>Usuário</th>
            <th>De</th>
            <th>Para</th>
            <th>Ações</th>
          </tr>
          <tr
            v-for="compartilhamento in processo.compartilhamento"
            :key="compartilhamento.id"
          >
            <td>{{ compartilhamento.created_at }}</td>
            <td>{{ compartilhamento.user.name }}</td>
            <td>
              {{
                compartilhamento.user.setor === null
                  ? '-'
                  : compartilhamento.user.setor.nome
              }}
            </td>
            <td>{{ compartilhamento.setor.nome }}</td>
            <td>
              <b-button
                class="ml-2"
                size="sm"
                variant="danger"
                @click="excluirCompartilhamento(compartilhamento)"
                v-if="$helper.verificaPermissao('remover_compartilhamento')"
              >
                <b-icon icon="trash-fill"></b-icon>
              </b-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      setores: [],
      setoresCompartilhamento: [],
      manter_processo_unidade: true,
      compartilhamento: {
        processo_id: '',
        setores: [],
        mensagem: '',
      },
      signatarios: [],
    };
  },
  emits: ['processo'],
  methods: {
    getSetores() {
      const params = new URLSearchParams([['processo_id', this.processo.id]]);

      this.$http
        .get('setores', { params })
        .then(({ data }) => {
          data.forEach((setor) => {
            this.setores.push(setor);
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    addTag(newTag) {
      const tag = {
        nome: newTag,
      };
      this.setores.push(tag);
    },
    removerCompartilhamentoProcessoDaUnidade() {
      if (!this.manter_processo_unidade) {
        this.$swal({
          icon: 'warning',
          title:
            'Deseja realmente remover o compartilhamento com a sua unidade?',
          text: 'Esta ação não poderá ser desfeita!!',
          showDenyButton: true,
          confirmButtonText: 'Sim',
          confirmButtonColor: '#3085d6',
          denyButtonText: 'Cancelar',
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.$http
                .delete(`processos/${this.processo.id}/remover-da-unidade`)
                .then(() => {
                  this.$swal({
                    title:
                      'Compartilhamento do processo com a unidade removido com sucesso!',
                    icon: 'success',
                  });
                  this.$emit('processo', this.processo);
                })
                .catch((error) => {
                  this.$swal({ title: 'Erro!', icon: 'error' });
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    compartilharProcesso() {
      this.compartilhamento.processo_id = this.processo.id;
      this.compartilhamento.setores = this.setoresCompartilhamento;

      this.$http
        .post(`compartilhamentos/`, this.compartilhamento)
        .then(() => {
          this.$swal({
            title: 'Processo compartilhado com sucesso!',
            icon: 'success',
          });

          this.manter_processo_unidade = true;
          this.compartilhamento = {
            processo_id: '',
            setores: [],
            mensagem: '',
          };
          this.setoresCompartilhamento = [];
          this.$emit('processo', this.processo);
        })
        .catch((error) => {
          this.$swal({ title: 'Erro!', icon: 'error' });
          console.log(error);
        });
    },
    excluirCompartilhamento(compartilhamento) {
      this.$swal({
        icon: 'warning',
        title: 'Deseja realmente remover este compartilhamento?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(`compartilhamentos/${compartilhamento.id}`, {})
              .then(() => {
                this.$swal({
                  title: 'Compartilhamento removido com sucesso!',
                  text: `O Processo não está mais compartilhado com a unidade ${compartilhamento.setor.nome}`,
                  icon: 'success',
                });
                this.$emit('processo', this.processo);
                this.processo.compartilhamento =
                  this.processo.compartilhamento.filter((item) => {
                    return item !== compartilhamento;
                  });
                this.$emit("processo", this.processo);
              })
              .catch((error) => {
                this.$swal({ title: 'Erro!', icon: 'error' });
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async getSignatarios() {
      let user_id = localStorage.getItem('user_id');
      try {
        const response = await this.$http.get('user-signatario/' + user_id);
        response.data.forEach((signatarios) => {
          if (signatarios.pivot.status === null) {
            let tipo = signatarios.nome_original;
            tipo = tipo.substr(0, tipo.indexOf('-'));
            signatarios.tipo = tipo;
            this.signatarios.push(signatarios);
          }
        });
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  props: {
    processo: {
      type: Object,
    },
  },
  computed: {
    setoresSemCompartilhamento() {
      const setoresCompartilhados = this.processo.compartilhamento.map(
        (compartilhamento) => compartilhamento.setor
      );

      let setorEstaCompartilhado = {};

      return this.setores.filter((setor) => {
        setorEstaCompartilhado = setoresCompartilhados.find(
          (setorCompartilhado) => setorCompartilhado.id === setor.id
        );

        return !setorEstaCompartilhado;
      });
    },
  },
  created() {
    this.getSetores();
    this.getSignatarios();
  },
};
</script>
